var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","dense":"","outlined":"","rules":_vm.rules.required.concat([
                  _vm.rules.maxLength(_vm.nombre, 50),
                  _vm.rules.requiredTrim(_vm.nombre)
                ])},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombre"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{ref:"localidad",attrs:{"items":_vm.localidades,"item-text":"value","item-value":"id","label":"Localidad","outlined":"","clearable":"","dense":"","rules":_vm.localidadSelected !== 0 ? _vm.rules.required : []},model:{value:(_vm.localidadSelected),callback:function ($$v) {_vm.localidadSelected=$$v},expression:"localidadSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"label":"Código postal","type":"text","dense":"","rules":_vm.rules.validCodigoPostal,"outlined":""},model:{value:(_vm.codigoPostal),callback:function ($$v) {_vm.codigoPostal=$$v},expression:"codigoPostal"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Domicilio","dense":"","outlined":"","rules":_vm.entFinId === 0 ? [_vm.rules.maxLength(_vm.domicilio, 50)] :
                _vm.rules.required.concat([
                  _vm.rules.maxLength(_vm.domicilio, 50),
                  _vm.rules.requiredTrim(_vm.domicilio)
                ])},model:{value:(_vm.domicilio),callback:function ($$v) {_vm.domicilio=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"domicilio"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####################'),expression:"'####################'"}],attrs:{"label":"Teléfono","type":"text","dense":"","outlined":""},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{ref:"formaPago",attrs:{"items":_vm.formasPago,"item-text":"value","item-value":"id","label":"Forma de pago","outlined":"","clearable":"","dense":""},model:{value:(_vm.formaPagoSelected),callback:function ($$v) {_vm.formaPagoSelected=$$v},expression:"formaPagoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{ref:"caja",attrs:{"items":_vm.cajas,"item-text":"cajaNombre","item-value":"cajaId","label":"Caja","outlined":"","clearable":"","dense":""},model:{value:(_vm.cajaSelected),callback:function ($$v) {_vm.cajaSelected=$$v},expression:"cajaSelected"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }