<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="entidadesFinancieras"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right">
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="openModal(item.entFinId)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar entidad</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="deleteEntidadesFinancieras(item.entFinId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar entidad</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="45rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditEntidadFinanciera
        :entFinId="this.entFinId"
        @closeAndReload="closeAndReload"
      ></EditEntidadFinanciera>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditEntidadFinanciera from "@/components/modules/cuotas/configuracion/EditEntidadFinanciera.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  components: { PageHeader, DeleteDialog, EditEntidadFinanciera, GoBackBtn },
  name: "EntidadesFinancieras",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    showDeleteModal: false,
    idToDelete: null,
    routeToGo: "FormasPagoYEntidadesCobranza",
    title: enums.titles.ENTIDADES_FINANCIERAS_APP,
    titleDelete: "¿Eliminar entidad?",
    rules: rules,
    entidadesFinancieras: [],
    entidadFinanciera: {},
    entFinId: null,
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "nombre"
      },
      {
        text: "Domicilio",
        value: "domicilio",
        sortable: false
      },
      {
        text: "Localidad",
        value: "localidadNom",
        sortable: false
      },
      {
        text: "Forma de pago",
        value: "formaPagoNom",
        sortable: false
      },
      {
        text: "Caja",
        value: "cajaNom",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadEntidadesFinancieras();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getEntidadesFinancieras: "configuracion/getEntidadesFinancieras",
      deleteConfiguracionEntidadesFinancieras:
        "configuracion/deleteConfiguracionEntidadesFinancieras",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_ENTIDAD_FINANCIERA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ENTIDAD_FINANCIERA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_ENTIDAD_FINANCIERA:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadEntidadesFinancieras() {
      const data = await this.getEntidadesFinancieras();
      this.entidadesFinancieras = data;
    },
    deleteEntidadesFinancieras(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteConfiguracionEntidadesFinancieras({
        entFinId: this.idToDelete
      });

      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadEntidadesFinancieras();
      }
    },
    openModal(entFinId) {
      this.openModalEdit = true;
      this.entFinId = entFinId;
    },

    closeAndReload() {
      this.openModalEdit = false;
      this.loadEntidadesFinancieras();
    }
  }
};
</script>

<style scoped></style>
